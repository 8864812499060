import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router'; // Import createRouter and createWebHistory
import router from './router'; // Import your router configuration

const app = createApp(App);

const myRouter = createRouter({
  history: createWebHistory(),
  routes: router.options.routes, // Use the routes from your imported router configuration
});

app.use(myRouter); // Use the created router instance

app.mount('#app');
