/* eslint-disable */
<template>
    <div>
      <header>
        <div class="pattern">
          <div class="container">
            <div class="pattern-inner"></div>
          </div>
        </div>
  
        <div class="container">
          <h1>
            <span>PROJECT A-R-C-H-I-V-E</span>
            <small> Photo Archive</small>
          </h1>
        </div>
  
        <div class="container">
          <p>
            To showcase the pictures I've taken over the years.
          </p>
        </div>
      </header>
  
      <main>
        <div class="container">
          <div class="menubar">
            <router-link class="router-link" to="/">Home</router-link>
            <a class="router-link" href="https://kyle.day">My Main Website</a>
          </div>
          <br>
  
          <div class="content">
            <p>
              I've been working on this for a while but I wanted to showcase and document the photos I've taken over the years.
              <br>
              So this is a collection of the many photos I've taken and wanted to show.
              <br>
              As I continue this project, it should get bigger and bigger. It's interesting to see what I could make in a short period of time.
            </p>
          </div>
        </div>
      </main>
  
      <div class="pattern">
        <div class="container">
          <div class="pattern-inner"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      navigateTo(url) {
        window.location.href = url;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Your CSS styles here */
  </style>
  