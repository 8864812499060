import { createRouter, createWebHistory } from 'vue-router';
import HomeScreen from '../views/HomeScreen.vue';
import AboutScreen from '../views/AboutScreen.vue';

const routes = [
  {
    path: '/',
    component: HomeScreen,
  },
  {
    path: '/about',
    component: AboutScreen,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
