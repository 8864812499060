/* eslint-disable */
<template>
  <div>
    <header>
      <div class="pattern">
        <div class="container">
          <div class="pattern-inner"></div>
        </div>
      </div>

      <div class="container">
        <h1>
          <span>PROJECT A-R-C-H-I-V-E</span>
          <small> Photo Archive</small>
        </h1>
      </div>

      <div class="container">
        <p>
          To showcase the pictures I've taken over the years.
        </p>
      </div>
    </header>

    <main>
      <div class="container">
        <div class="menubar">
          <router-link class="router-link" to="/about">About</router-link>
          <a class="router-link" href="https://kyle.day">My Main Website</a>
          <a class="router-link" href="https://github.com/TheKyleChau/CamerasWebsite">Source Code</a>
          <a class="router-link" href="https://www.linkedin.com/in/kylechau/">LinkedIn</a>
        </div>
        <br>

        <div class="content" id="image-gallery"></div>
      </div>
    </main>

    <div class="pattern">
      <div class="container">
        <div class="pattern-inner"></div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  methods: {
    loadImages() {
      const photosDirectory = require.context('@/photos/', false, /\.(jpe?g|png|gif)$/);

      const imageLinks = photosDirectory.keys().map(key => photosDirectory(key));

      this.appendImages(imageLinks);
    },
    appendImages(imageLinks) {
      const gallery = document.getElementById("image-gallery");

      imageLinks.forEach(link => {
        const img = new Image();
        img.src = link;

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          let cssClass;

          if (width > height) {
            cssClass = "thumbnail-land";
          } else if (width < height) {
            cssClass = "thumbnail-ver";
          } else {
            cssClass = "thumbnail-square";
          }

          const imgElement = document.createElement("img");
          imgElement.src = link;
          imgElement.classList.add(cssClass);

          gallery.appendChild(imgElement);
        };
      });
    },
  },
  mounted() {
    this.loadImages();
  },
};
</script>
  
  
<style scoped>
/* Your CSS styles here */
</style>
  