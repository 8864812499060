<template>
    <div id="app">
        <router-view />
    </div>
</template>
  
<script>
export default {
    mounted() {
        this.loadAppropriateStyles();
        window.addEventListener('resize', this.loadAppropriateStyles);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.loadAppropriateStyles);
    },
    methods: {
        loadAppropriateStyles() {
            const isMobile = window.innerWidth <= 768;

            if (isMobile) {
                import('@/assets/mobile.css').then(() => {
                    console.log('Mobile styles loaded');
                });
            } else {
                import('@/assets/style.css').then(() => {
                    console.log('Web styles loaded');
                });
            }
        },
    },
};
</script>
